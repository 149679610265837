import React from 'react'
import { Link } from 'gatsby'

import { PageLayout, PageBreadcrumb } from '../../components'
import { t } from '../../lib/locale'

const ContentVacaturesTemplate = ({ pageContext }) => {
  const { lang, page } = pageContext

  // const renderNL = (
  //   <>
  //     <div className="row">
  //       <div className="col-12">
  //         <p>Geen vacatures momenteel, maar je kan altijd spontaan solliciteren.</p>
  //         <p>Stuur dan snel jouw CV en motivatie naar <a href="mailto:valerie@shopupnorth.com">valerie@shopupnorth.com</a>!</p>
  //       </div>
  //     </div>
  //   </>
  // )
  //
  // const renderEN = (
  //   <>
  //     <div className="row">
  //       <div className="col-12">
  //         <p>We are not looking for specific profiles at the moment, but that shouldn't stop you from getting in touch with us.</p>
  //         <p>So if you are interested in working for Up North, quickly send your CV and motivation to <a href="mailto:valerie@shopupnorth.com">valerie@shopupnorth.com</a>!</p>
  //       </div>
  //     </div>
  //   </>
  // )

  const renderNL = (
    <>
      <div className="row">
        <div className="col-12">
          <h4>11/02/2021</h4>
          <h2>VACATURE JS DEVELOPER - GEEL</h2>
          <p>We zoeken iemand die mee helpt aan de ontwikkeling van onze frontend en backend systemen, waarop de webshop en onze winkel momenteel draait. Afhankelijk van jouw kennis en interesses, ontwikkel je mee op de frontend of backend, of beide, waarbij we in een agile methode continu grote en kleine features uitrollen naar onze eindgebruikers. Je wordt betrokken in architecturale discussies, alsook in discussies omtrent hosting, testing, availability and the list goes on.</p>
          <p>
            <Link className="btn btn-link" to={`/nl/vacatures/js-developer`}>
              Meer lezen
            </Link>
          </p>
        </div>
      </div>
    </>
  )

  const renderEN = (
    <>
      <div className="row">
        <div className="col-12">
          <h4>11/02/2021</h4>
          <h2>VACATURE JS DEVELOPER - GEEL</h2>
          <p>We are looking for someone to help build and maintain our IT systems for our webshop and shop in Geel. Depending on your expertise and interests, you will develop on the frontend or the backend, or both. We do this in an agile way, where we continuously deliver big and small features to our end-users. You will have a say in architectural discussions, as well as dicussions around hosting, testing, high availability and the list goes on.</p>
          <p>
            <Link className="btn btn-link" to={`/en/jobs/js-developer`}>
              Read more
            </Link>
          </p>
        </div>
      </div>
    </>
  )

  return (
    <PageLayout className="shopping-bag" lang={lang} switcher={page}>
      <PageBreadcrumb current={page} items={page.breadcrumbs} lang={lang} />
      <h1 className="mb-4">{t(page, 'title', lang)}</h1>
      {+lang.id_lang === 1 && renderEN}
      {+lang.id_lang === 4 && renderNL}
    </PageLayout>
  )
}

export default ContentVacaturesTemplate
